// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-explore-article-js": () => import("./../../../src/templates/explore-article.js" /* webpackChunkName: "component---src-templates-explore-article-js" */),
  "component---src-templates-explore-overview-js": () => import("./../../../src/templates/explore-overview.js" /* webpackChunkName: "component---src-templates-explore-overview-js" */),
  "component---src-templates-explore-topic-js": () => import("./../../../src/templates/explore-topic.js" /* webpackChunkName: "component---src-templates-explore-topic-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pathway-article-js": () => import("./../../../src/templates/pathway-article.js" /* webpackChunkName: "component---src-templates-pathway-article-js" */),
  "component---src-templates-pathway-questionnaire-js": () => import("./../../../src/templates/pathway-questionnaire.js" /* webpackChunkName: "component---src-templates-pathway-questionnaire-js" */)
}

